import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  AfterViewInit,
  HostBinding,
} from '@angular/core';
import { BasefieldComponent } from '@app/formly/types/basefield/basefield.component';

@Component({
  selector: 'text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyTextAreaComponent extends BasefieldComponent implements AfterViewInit {
  @HostBinding('class.stretched') get isStretched() {
    return this.props.stretched;
  }

  @HostListener('cut')
  @HostListener('drop')
  @HostListener('paste')
  @HostListener('change')
  @HostListener('keydown')
  change() {
    this.resize();
  }

  ngAfterViewInit(): void {
    this.resize();
  }

  modeChange(): void {
    this.resize();
  }

  resize(): void {
    setTimeout(() => {
      const textarea = document.getElementById('textarea') as HTMLTextAreaElement;

      if (textarea) {
        textarea.style.height = `0px`;
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    });
  }
}
