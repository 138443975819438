import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BasefieldV2Component } from '@app/formly/types/v2/basefield-v2/basefield-v2.component';

@Component({
  selector: 'multicheckbox-v2',
  templateUrl: './multicheckbox-v2.component.html',
  styleUrls: ['./multicheckbox-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MulticheckboxV2Component extends BasefieldV2Component {
  onChange(value: string, checked: boolean) {
    this.formControl.markAsDirty();
    if (this.props.type === 'array') {
      this.formControl.patchValue(
        checked
          ? [...(this.formControl.value || []), value]
          : [...(this.formControl.value || [])].filter((o) => o !== value),
      );
    } else {
      this.formControl.patchValue({ ...this.formControl.value, [value]: checked });
    }
    this.formControl.markAsTouched();
  }

  isChecked(option: any) {
    const value = this.formControl.value;

    return (
      value &&
      (this.props.type === 'array' ? value.indexOf(option.value) !== -1 : value[option.value])
    );
  }
}
