import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  HostListener,
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { DateTime, Duration } from 'luxon';
import { fromISO8601, supportedDateFormats, toISO8601 } from '@app/formly/types/date/date-helpers';
import { replaceExtraSeparators } from '@app/shared/utils/date-utils';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { ModalV2Service } from '@app/ui-v2/services/modal-v2.service';

@Component({
  selector: 'date-v3',
  templateUrl: './date-v3.component.html',
  styleUrls: ['./date-v3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateV3Component
  extends FieldType<FormlyFieldConfig & { dataId: any }>
  implements OnInit
{
  @ViewChild('dateInput') dateInput: ElementRef;

  showDatepicker: boolean;
  dateInputModel = new FormControl<string | null>(null);
  bsModel = new UntypedFormControl();

  shortYearFormats = [
    'LLL/dd/yy', // Feb 02 23
    'LLL/d/yy', // Feb 2 23
    'LL/dd/yy', // 06 23 23
    'LL/d/yy', // 02 2 23
    'L/dd/yy', // 2 02 23
    'L/d/yy', // 2 2 23
    'LLL/dd', // Feb 02
    'LLL/d', // Feb 2
    'LL/dd', // 02 02
    'LL/d', // 02 2
    'L/dd', // 2 02
    'L/d', // 2 2
  ];

  constructor(
    public cdRef: ChangeDetectorRef,
    protected modalService: ModalV2Service,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onEscape(): void {
    if (this.showDatepicker) {
      this.closeDatepicker();
    }
  }

  ngOnInit() {
    this.formControl.setErrors(null);

    if (this.formControl.value) {
      this.formControl.updateValueAndValidity();
      const date = DateTime.fromJSDate(fromISO8601(this.formControl.value as string));
      this.dateInputModel.setValue(date.toFormat('LL/dd/yyyy'));
      this.bsModel.setValue(date.toJSDate());
    }
  }

  triggerDatepicker() {
    if (this.props.disabled) return;

    this.showDatepicker = true;
    this.cdRef.detectChanges();

    if (this.showDatepicker) {
      this.dateInput.nativeElement.focus();
      this.modalService.preventCloseOnEscape(this.id);
    }
  }

  onDateInputChange(closeDatepicker = false) {
    const dateInputValue = replaceExtraSeparators(this.dateInputModel.value || '');
    if (!dateInputValue && !this.props.required) {
      this.clearValue();
      return;
    }

    const parsedDate = this.parseDateString(dateInputValue);

    if (parsedDate.isValid) {
      this.formControl.setErrors(null);
      this.formControl.setValue(toISO8601(parsedDate.toJSDate()));
      this.dateInputModel.setValue(parsedDate.toFormat('LL/dd/yyyy'));
      this.bsModel.setValue(parsedDate.toJSDate());
    } else {
      this.formControl.setValue('');
      this.formControl.setErrors({ invalidDate: true });
    }

    this.formControl.markAsDirty();

    if (closeDatepicker) {
      this.closeDatepicker();
    }
  }

  parseDateString(input: string): DateTime {
    let parsedDate: DateTime = DateTime.now();
    let parsedFormat = '';
    for (let i = 0; i < supportedDateFormats.length; i++) {
      parsedDate = DateTime.fromFormat(input, supportedDateFormats[i]);
      if (parsedDate.isValid) {
        parsedFormat = supportedDateFormats[i];
        break;
      }
    }

    if (this.props.onlyDatesInThePast) {
      const isShortYearFormat = this.shortYearFormats.includes(parsedFormat);
      if (parsedDate > DateTime.now() && isShortYearFormat) {
        parsedDate = parsedDate.minus(Duration.fromObject({ years: 100 }));
      }
    }

    return parsedDate;
  }

  onDatepickerChange(newDate: Date) {
    if (newDate && newDate !== this.bsModel.value) {
      this.formControl.setErrors(null);
      this.formControl.setValue(toISO8601(newDate));
      this.formControl.markAsDirty();
      this.bsModel.setValue(newDate);
      const d = DateTime.fromJSDate(newDate);
      this.dateInputModel.setValue(d.toFormat('LL/dd/yyyy'));
      this.closeDatepicker();
    }
  }

  clearValue() {
    this.dateInputModel.setValue('');
    this.formControl.setValue('');
    this.bsModel.setValue(null);
    this.formControl.markAsDirty();
    this.cdRef.detectChanges();
  }

  closeDatepicker() {
    this.showDatepicker = false;
    this.modalService.allowCloseOnEscape(this.id);
    this.cdRef.detectChanges();
  }
}
