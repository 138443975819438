import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { UntilDestroy } from '@app/shared/utils/until-destroy';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';

@UntilDestroy()
@Component({
  selector: 'formly-chips-v2',
  templateUrl: './formly-chips-v2.component.html',
  styleUrls: ['./formly-chips-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyChipsV2Component extends BasefieldV2Component implements OnInit {
  ngOnInit(): void {
    if (this.props.initial?.length) {
      this.formControl.setValue(
        this.props.initial?.map(({ value }: any) => {
          if (this.props.isFilter) {
            return value;
          }
          return { id: value };
        }),
      );
    }
  }

  onItemSelectionChanged(selectedItems: DropdownItem[]) {
    this.formControl.setValue(
      selectedItems.map(({ value }) => {
        if (this.props.isFilter) {
          return value;
        }
        return { id: value };
      }),
    );
    this.formControl.markAsDirty();
  }
}

export type ChipsFormControlValue = { id: unknown };
