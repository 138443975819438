<field-layout [isReadonly]="isReadonly">
  <editable editableView [attr.data-id]="field.dataId" #editableRef>
    <ng-template viewMode>
      <div [ngClass]="{'input-error-border': showError}" class="view-mode">
        <typeahead-value-view
          [value]="formControl.value"
          [placeholder]="field.props?.placeholder"
          [label]="selectedItem?.label"
          [emitFullObject]="to.emitFullObject"
        ></typeahead-value-view>

        <rdr-svg-icon
          class="pencil-icon me-1 ms-auto"
          key="pencil-create"
          color="var(--in-place-icon)"
        ></rdr-svg-icon>
      </div>
    </ng-template>
    <ng-template editMode>
      <div *ngIf="!to.loaded" class="ms-1 spinner">
        <loading-spinner></loading-spinner>
      </div>
      <ng-container *ngIf="options$ | ensureObservable | async">
        <div
          class="m-0 position-relative"
          [ngClass]="{
          groupable: to.groupedFields
        }"
        >
          <input
            #typeaheadInputRef
            (radarOnEscape)="cancel()"
            (focus)="onFocus()"
            [formControl]="innerModel"
            [formlyAttributes]="field"
            [typeahead]="typeahead$"
            [typeaheadItemTemplate]="to.itemTemplate"
            [typeaheadOptionsLimit]="typeaheadOptions.length < 40 ? typeaheadOptions.length : 40"
            (typeaheadOnSelect)="selectOption($event)"
            [typeaheadGroupField]="to.groupedFields ? 'groupBy' : ''"
            [typeaheadMinLength]="0"
            [typeaheadScrollable]="true"
            [typeaheadOptionsInScrollableView]="to.typeaheadOptionsInScrollableView || 12"
            editableFocusable
            editableOnEnter
            editableOnEscape
            typeaheadOptionField="label"
            autocomplete="off"
            type="text"
            class="formly-input"
            [ngClass]="{
            'has-clear': to.clearable && to.loaded && formControl.value
          }"
          />
        </div>

        <rdr-svg-icon
          *ngIf="to.clearable && to.loaded && formControl.value !== null"
          (click)="clear()"
          class="position-absolute clear-icon"
          color="var(--gray-600)"
          key="circle-x"
        ></rdr-svg-icon>
      </ng-container>
    </ng-template>
  </editable>

  <typeahead-value-view
    readonlyView
    [attr.data-id]="field.dataId"
    [value]="formControl.value"
    [placeholder]="field.props?.placeholder"
    [label]="selectedItem?.label"
    [emitFullObject]="to.emitFullObject"
  ></typeahead-value-view>
</field-layout>
