import { Component, ChangeDetectionStrategy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BasefieldComponent } from '@app/formly/types/basefield/basefield.component';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';

@UntilDestroy()
@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent extends BasefieldComponent implements OnInit {
  @ViewChild('inputRef') inputRef: ElementRef;

  isWide = false;
  hasFocus: boolean;

  ngOnInit() {
    // allows clearing and shrinking the input from outside, yet we still
    // want it to remain wide when focused
    this.formControl.valueChanges.pipe(UntilDestroyed(this)).subscribe((val) => {
      if (!this.hasFocus && !val) {
        this.isWide = false;
      }
    });
  }

  cancel() {
    super.cancel();
    this.formControl.setValue('');
    this.inputRef.nativeElement.blur();
  }

  onFocus() {
    this.isWide = true;
    this.hasFocus = true;
    setTimeout(() => this.inputRef.nativeElement.focus());
  }

  checkActiveState() {
    this.hasFocus = false;
    if (this.props.isFilterSearch || !this.formControl.value) {
      this.isWide = false;
    }
  }
}
