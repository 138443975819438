import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';

@Component({
  selector: 'count-v2',
  templateUrl: './count-v2.component.html',
  styleUrls: ['./count-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountV2Component extends BasefieldV2Component {
  decrement() {
    if (this.props.readonly) return;

    const value = Number(this.formControl.value) || 0;
    const min = this.props.min || 0;

    if (value > 0 && value > min) {
      this.formControl.setValue(value - 1);
      this.formControl.markAsDirty();
    }
  }

  increment() {
    if (this.props.readonly) return;

    const value = Number(this.formControl.value) || 0;
    this.formControl.setValue(value + 1);

    this.formControl.markAsDirty();
  }
}
