<field-layout [isReadonly]="isReadonly">
  <div editableView class="d-flex align-items-center datetime">
    <editable (modeChange)="onEditableModeChange($event)" #editable>
      <ng-template viewMode>
        <div class="view-mode p-0">
          <date-range-value-view
            [value]="formControl.value"
            [placeholder]="field.props?.placeholder"
          ></date-range-value-view>
          <rdr-svg-icon
            class="pencil-icon me-1 ms-auto"
            key="pencil-create"
            color="var(--in-place-icon)"
          ></rdr-svg-icon>
        </div>
      </ng-template>
      <ng-template editMode>
        <input
          (bsValueChange)="onDatePicked($event)"
          (blur)="onBlur()"
          (radarOnEscape)="cancel()"
          [isOpen]="isOpen"
          [formControl]="innerModel"
          [formlyAttributes]="field"
          [bsConfig]="{
            adaptivePosition: true,
            customTodayClass: 'custom-today',
            containerClass: 'theme-default',
            maxDateRange: field.props?.maxDateRange
          }"
          #dp="bsDaterangepicker"
          #input
          type="text"
          class="formly-input is-filter"
          bsDaterangepicker
          radarEditableFocus
          editableOnEnter
          editableOnEscape
          radarOnEnter
        />

        <rdr-svg-icon
          *ngIf="formControl.value?.length"
          (click)="onClear()"
          class="position-absolute clear-icon"
          color="var(--gray-600)"
          key="circle-x"
        ></rdr-svg-icon>
      </ng-template>
    </editable>
  </div>

  <date-range-value-view
    [attr.data-id]="field.dataId"
    [value]="formControl.value"
    [placeholder]="field.props?.placeholder"
    readonlyView
  ></date-range-value-view>
</field-layout>
