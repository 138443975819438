import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import IMask from 'imask';
import { BasefieldComponent } from '../basefield/basefield.component';

@Component({
  selector: 'numeric',
  templateUrl: './numeric.component.html',
  styleUrls: ['./numeric.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericComponent extends BasefieldComponent implements OnInit {
  imaskNumeric: IMask.MaskedNumberOptions;

  ngOnInit() {
    this.imaskNumeric = {
      mask: Number,
      signed: !this.props.positiveOnly,
      scale: this.props.integerOnly ? 0 : 3,
      thousandsSeparator: this.props.delimiter || ' ',
    };
  }
}
