import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-input-wrapper',
  templateUrl: './formly-input-wrapper.component.html',
  styleUrls: ['./formly-input-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyInputWrapperComponent extends FieldWrapper<FormlyFieldConfig & { type: any }> {}
