<div
  class="d-flex"
  [class.compact]="to.compact === true"
  [ngClass]="{'pb-2': !to.resetPaddingBottom}"
>
  <label *ngIf="!to.hideLabel" class="label" [attr.for]="id" [attr.data-id]="id+'-label'">
    <typography look="formLabel">{{ to.label }}</typography>
    <typography look="small" *ngIf="to.required && to.hideRequiredMarker !== true" class="required"
      >*</typography
    >
  </label>

  <div class="d-flex align-items-center flex-column">
    <div class="d-flex align-items-center" hoverClass>
      <ng-template #fieldComponent></ng-template>

      <ng-container *ngIf="to.showCopyToClipboard">
        <copy-to-clipboard
          *ngIf="field.props?.calculatedValue; else regularVal"
          class="ms-1 copy-tcb"
          [value]="field.props?.calculatedValue | async | formlyFieldFormattedValue : field.type"
        ></copy-to-clipboard>

        <ng-template #regularVal>
          <copy-to-clipboard
            class="ms-1 copy-tcb"
            [value]="field.formControl?.value | formlyFieldFormattedValue : field.type"
          ></copy-to-clipboard>
        </ng-template>
      </ng-container>
    </div>

    <validation-message *ngIf="showError" [field]="field"></validation-message>
  </div>
</div>
