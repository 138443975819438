import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BasefieldV2Component } from '@app/formly/types/v2/basefield-v2/basefield-v2.component';
import { DropdownItem } from '@ui/components/dropdown.model';

@Component({
  selector: 'formly-typeahead-v2',
  templateUrl: './formly-typeahead-v2.component.html',
  styleUrls: ['./formly-typeahead-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyTypeaheadV2Component extends BasefieldV2Component implements OnInit {
  selectedItem: DropdownItem | null;

  ngOnInit(): void {
    this.selectedItem = this.props.initial;
  }

  selectOption(item: DropdownItem): void {
    if (this.props.emitFullObject) {
      this.formControl.setValue(item);
    } else {
      this.formControl.setValue(item?.value);
    }
    this.selectedItem = item;
    this.formControl.markAsDirty();
  }
}
