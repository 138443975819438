<div class="view-mode" [attr.data-id]="field.dataId">
  <div *ngIf="field.props?.calculatedValue | async as calculatedValue; else plainVal">
    {{ calculatedValue }}
  </div>
  <ng-template #plainVal>
    <ng-container *ngIf="formControl.value; else placeholder">
      {{ formControl.value }}
    </ng-container>
  </ng-template>
  <ng-template #placeholder>
    <typography look="inputPlaceholder"> {{ field.props?.placeholder }} </typography>
  </ng-template>
</div>
