<editable [attr.data-id]="field.dataId" [ngClass]="{'readonly': to.readonly}" #editable>
  <ng-template viewMode>
    <div
      (click)="changeMode($event)"
      [ngClass]="{'input-error-border': showError}"
      class="view-mode"
    >
      <ng-container *ngIf="!formControl.value">
        <ng-container *ngIf="to.itemType === 'agentItem'">
          <user-picture class="pe-1" [picture]="selectedItem?.picture"></user-picture>
        </ng-container>
        <typography look="inputPlaceholder"> {{ field.props?.placeholder }} </typography>
      </ng-container>

      <typography *ngIf="formControl.value">
        <div *ngIf="!selectedItem?.colored; else coloredCaption" class="d-flex align-items-center">
          <ng-container *ngIf="to.itemType === 'agentItem'">
            <user-picture class="pe-1" [picture]="selectedItem?.picture"></user-picture>
          </ng-container>
          {{ selectedItem?.label }}
        </div>
        <ng-template #coloredCaption>
          <colored-status [status]="selectedItem!.value || ''">
            {{ selectedItem!.label }}
          </colored-status>
        </ng-template>
      </typography>
      <rdr-svg-icon
        *ngIf="!to.compact"
        class="pencil-icon me-1 ms-auto"
        key="pencil-create"
        color="var(--in-place-icon)"
      ></rdr-svg-icon>
    </div>
  </ng-template>
  <ng-template editMode>
    <ng-container *ngIf="to.options | ensureObservable | async as opts; else loading">
      <dropdown
        class="ps-1 pe-1"
        [isOpen]="true"
        [look]="dropdownType.link"
        [selected]="selectedItem"
        [placeholder]="to.placeholder || ''"
        [options]="opts"
        [itemType]="to.itemType"
        [groupedFields]="to.groupedFields"
        (itemClick)="optionSelected($event)"
      ></dropdown>
    </ng-container>
    <ng-template #loading>
      <div class="ms-1 spinner">
        <loading-spinner></loading-spinner>
      </div>
    </ng-template>
  </ng-template>
</editable>
