import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'repeat',
  templateUrl: './repeat.component.html',
  styleUrls: ['./repeat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyRepeatComponent extends FieldArrayType<any> {
  showConfirmDelete: Record<number, boolean> = {};

  setDeleteConfirm(index: number, val: boolean) {
    this.showConfirmDelete[index] = val;

    for (const k in this.showConfirmDelete) {
      if (Number(k) !== index) {
        this.showConfirmDelete[k] = !val;
      }
    }
  }
}
