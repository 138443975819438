import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { GetPresignedUrlGQL } from '@app/generated/graphql';
import { map } from 'rxjs/operators';
import { BasefieldComponent } from '../basefield/basefield.component';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';

@UntilDestroy()
@Component({
  selector: 'file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileComponent extends BasefieldComponent implements OnInit {
  selectedItem: any;

  @ViewChild('inputTemplate') inputTemplate: ElementRef;

  constructor(
    private getPresignedUrlGQL: GetPresignedUrlGQL,
    cdRef: ChangeDetectorRef,
  ) {
    super(cdRef);
  }

  ngOnInit(): void {
    this.props.initial?.pipe(UntilDestroyed(this)).subscribe((value: any) => {
      if (value) {
        this.selectedItem = value;
        this.cdRef.detectChanges();
      }
    });
  }

  upload(): void {
    this.inputTemplate.nativeElement.click();
  }

  change({ target }: Event): void {
    const files = (target as HTMLInputElement).files;
    this.formControl.setValue(files);
    this.selectedItem = files && files[0];
  }

  remove(): void {
    if (this.inputTemplate) this.inputTemplate.nativeElement.value = null;
    this.formControl.setValue(this.selectedItem?.id);
    this.selectedItem = null;
  }

  download(): void {
    if (!this.selectedItem.id) return;

    const data = { [this.props.filetype]: this.selectedItem.id };
    this.getPresignedUrlGQL
      .fetch(data)
      .pipe(map(({ data }) => window.open(data.getPresignedUrl, '_blank')))
      .subscribe();
  }

  withoutLink(): boolean {
    return this.selectedItem instanceof File;
  }
}
